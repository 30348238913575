<template>
  <el-carousel indicator-position="outside" id="el-carousel" :autoplay="true" height="162px">
    <el-carousel-item v-for="item in pcImgArr" :key="item.id">
      <a @click="bannerBtn" :href="item.url" target="_blank">
        <el-image :src="item.img" fit="contain" />
      </a>
    </el-carousel-item>
  </el-carousel>
</template>
<script>
export default {
  props: {
    boxWidth: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      pcImgArr: [{
        id: 1,
        img: require('@/assets/banner02.jpg'),
        txt: '',
        url: 'https://travelcontentsapp.com/en'
      }],
      phoneImgArr: [{
        id: 1,
        img: require('@/assets/usj-super-nintendo-world-overall-view-we-are-mario-b.jpg'),
        txt: ''
      },
      {
        id: 2,
        img: require('@/assets/usj-no-limit-cho-genki-tokku-b.jpg'),
        txt: ''
      }],

      bannerWidth: 0,
      bannerHeight: 0,
      // Swiper 配置项
    }
  },
  methods: {
    bannerBtn() {
      // console.log('记录');
    // google统计-分销商访问商品事件埋点
    this.$gtag.event("adBannerTcapp", {
        event_category: "adBannerTcapp",
        event_label: "adBannerTcapp",
        value: 1000
      });
    }
  },
  watch: {
    boxWidth(val) {
      this.bannerWidth = window.getComputedStyle(document.getElementsByClassName('el-carousel__container')[0]).width
      this.bannerHeight = parseInt(this.bannerWidth) / 5.74 + 'px' //动态计算 banner的高度 5.74是根据图片的宽度除以图片的高度得到的
      document.getElementsByClassName('el-carousel__container')[0].style.height = this.bannerHeight
    }
  },
  mounted() {
    this.bannerWidth = window.getComputedStyle(document.getElementsByClassName('el-carousel__container')[0]).width
    this.bannerHeight = parseInt(this.bannerWidth) / 5.74 + 'px' //动态计算 banner的高度  5.74是根据图片的宽度除以图片的高度得到的
    document.getElementsByClassName('el-carousel__container')[0].style.height = this.bannerHeight
    // new Swiper('.swiper-container', {
    //   loop: false,
    //   // loopAdditionalSlides: 1,
    //   speed: 1000,
    //   autoplay: false,
    //   // 如果需要分页器
    //   // pagination: '.swiper-pagination',
    //   // 如果需要前进后退按钮
    //   nextButton: '.swiper-button-next',
    //   prevButton: '.swiper-button-prev',
    //   // 如果需要滚动条
    //   // scrollbar: '.swiper-scrollbar',
    // })
  }
}
</script>
<style lang="scss" scoped>
.swiper-container {
  width: 100%;
  height: 100%;
  // margin: 20px auto;
  // overflow: hidden;
}
::v-deep .el-carousel__container {
  // height: 100% !important;
}
</style>