<template>
  <div>
    <el-dialog
      v-loading="loadingbuer"
      :close-on-click-modal="false"
      :before-close="handleClose"
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      width="33%"
    >
      <div slot="title" class="title">
        <!-- <i class="el-icon-arrow-left"></i> -->
        <div class="right" :style="{ borderLeft: (type == 4 ? 'none' : '1px solid #F2F2F2') }">
          <!-- <h1>POP共用テスト店舗</h1> -->
          <h1></h1>
          <span v-if="type == 1">ORDER SUMMARY</span>
          <span v-else-if="type == 2" @click="test">CREDIT CARD</span>
          <span
            v-else-if="type == 3"
            style="display: inline-block;width:130px;line-height: 16px;text-align: right;"
          >
            PAYMENT
            CONFIRMATION
          </span>
          <span
            v-else
            style="display: inline-block;width:130px;line-height: 16px;text-align: right;padding:12px 0"
          >
            PAYMENT
            FAILED
          </span>
        </div>
      </div>
      <!-- 支付第一步 -->
      <div class="box" v-if="type == 1">
        <div style="height:466px;padding-top: 8px;">
          <div class="zfMain">
            <div class="jiage">
              <p class="txt">Amount</p>
              <div class="price">
                <span class="price-txt">JPY</span>
                <span class="price-price">{{ formLabelAlign.money *100 || 0 | abs }}</span>
              </div>
              <span></span>
            </div>
          </div>
        </div>

        <div class="bottom-tishi">
          <div class="img"></div>
          <span v-show="formLabelAlign.jpo === 13">VeriTrans SECURE payment</span>
          <span v-show="formLabelAlign.jpo === 14">GMO SECURE payment</span>
        </div>
        <div class="btn-btm">
          <el-button @click="oneContinue" id="paySubmit">
            <span>CONTINUE</span>
            <i class="el-icon-arrow-right"></i>
          </el-button>
        </div>
      </div>
      <!-- 支付第二步 -->
      <div class="box" v-else-if="type == 2">
        <div style="height:466px;padding-top: 8px;overflow: auto;">
          <div class="zfMain" style="border-bottom: 0;">
            <div class="jiage">
              <p class="txt">Amount</p>
              <div class="price">
                <span class="price-txt">JPY</span>
                <span class="price-price">{{ formLabelAlign.money *100 || 0 | abs }}</span>
              </div>
              <span></span>
            </div>
          </div>
          <!-- 表单 -->
          <!-- asuka报错提示 -->
          <div v-if="asukaErrorBer" class="asukaError">
            <span>Please re-try your booking procedure after checking credit card number and etc.</span>
          </div>
          <div class="formBox" v-if="payType === 1">
            <el-form
              ref="formLabelAlignRules"
              :rules="formLabelAlignRules"
              label-position="top"
              label-width="80px"
              :model="formLabelAlign"
            >
              <el-form-item prop="cardNumber" label="Card number">
                <el-input
                  type="input"
                  id="cardNo"
                  @focus="focusFn(1)"
                  @blur="blurFn(1)"
                  onkeyup="value=value.replace(/[^\d]/g,'')"
                  maxlength="19"
                  v-model="formLabelAlign.cardNumber"
                ></el-input>
              </el-form-item>
              <div style="display: flex;">
                <el-form-item prop="expiryDate" label="Expiry date">
                  <el-input
                    id="card_exp"
                    type="input"
                    @focus="focusFn(2)"
                    @blur="blurFn(2)"
                    onkeyup="value=value.replace(/[^\d]/g,'')"
                    maxlength="4"
                    v-model="formLabelAlign.expiryDate"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="cvv" label="CVV">
                  <el-input
                    type="input"
                    @blur="blurFn(3)"
                    onkeyup="value=value.replace(/[^\d]/g,'')"
                    maxlength="4"
                    show-password
                    v-model="formLabelAlign.cvv"
                  ></el-input>
                </el-form-item>
              </div>
              <el-form-item prop="cardholderName" label="Cardholder Name">
                <el-input
                  id="card_holder"
                  @input="nameInput"
                  type="input"
                  @blur="blurFn(4)"
                  v-model.trim="formLabelAlign.cardholderName"
                ></el-input>
              </el-form-item>
              <el-form-item prop="cardholderEmail" label="Cardholder Email">
                <el-input
                  type="input"
                  id="email"
                  @blur="blurFn(5)"
                  v-model="formLabelAlign.cardholderEmail"
                ></el-input>
                <div v-if="emailErrTxt !== ''" class="asukaError">
                  <span>{{emailErrTxt}}</span>
                </div>
              </el-form-item>
              <el-form-item prop="cardholderPhone" label="Cardholder phone">
                <el-input
                  type="input"
                  id="phone"
                  @blur="blurFn(6)"
                  v-model="formLabelAlign.cardholderPhone"
                ></el-input>
              </el-form-item>
              <el-form-item prop="nationality" label="Nationality">
                <el-select
                  id="country"
                  v-model="formLabelAlign.nationality"
                  placeholder="Select"
                  filterable
                >
                  <el-option
                    v-for="(item, index) in areaList"
                    :key="index"
                    :label="item.areaName"
                    :value="item.areaId"
                  />
                </el-select>
              </el-form-item>
              <!-- 隐藏的表单 -->
              <el-form-item style="display: none;" prop="orderID" label="orderID">
                <el-input type="input" id="orderId" v-model="formLabelAlign.orderID"></el-input>
              </el-form-item>
              <el-form-item style="display: none;" prop="money" label="money">
                <el-input type="input" id="amount" v-model="formLabelAlign.money"></el-input>
              </el-form-item>
              <el-form-item style="display: none;" prop="tradeName" label="tradeName">
                <el-input type="input" class="products" v-model="formLabelAlign.tradeName"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div class="formBox" v-else>
            <el-form
              ref="formLabelAlignRules"
              :rules="formLabelAlignRules"
              label-position="top"
              label-width="80px"
              :model="formLabelAlign"
            >
              <el-form-item prop="cardNumber" label="Card number">
                <el-input
                  type="input"
                  id="cardNo"
                  @focus="focusFn(1)"
                  @blur="blurFn(1)"
                  onkeyup="value=value.replace(/[^\d]/g,'')"
                  maxlength="19"
                  v-model="formLabelAlign.cardNumber"
                ></el-input>
              </el-form-item>
              <div style="display: flex;">
                <el-form-item label="Expiry date(YYMM)">
                  <el-input
                    id="card_exp"
                    type="input"
                    @focus="focusFn(2)"
                    @blur="blurFn(2)"
                    onkeyup="value=value.replace(/[^\d]/g,'')"
                    maxlength="6"
                    v-model="formLabelAlign.cardExpire"
                  ></el-input>
                </el-form-item>
                <el-form-item label="CVV">
                  <el-input

                    type="input"
                    @blur="blurFn(3)"
                    onkeyup="value=value.replace(/[^\d]/g,'')"
                    maxlength="4"
                    show-password
                    v-model="formLabelAlign.securityCode"
                  />
                
                </el-form-item>
              </div>
              <el-form-item prop="cardholderName" label="Cardholder Name">
                <el-input
                  id="card_holder"
                  @input="nameInput"
                  type="input"
                  @blur="blurFn(4)"
                  v-model.trim="formLabelAlign.cardHolderName"
                ></el-input>
              </el-form-item>
              <el-form-item prop="cardholderEmail" label="Cardholder Email">
                <el-input type="input" id="email" @blur="blurFn(5)" v-model="formLabelAlign.cardholderEmail"></el-input>
                <div v-if="emailErrTxt !== ''" class="asukaError">
                  <span>{{emailErrTxt}}</span>
                </div>
              </el-form-item>
              <el-form-item prop="cardholderPhone" label="Cardholder phone">
                <el-input type="input" id="phone" @blur="blurFn(6)" v-model="formLabelAlign.cardholderPhone"></el-input>
              </el-form-item>
              <el-form-item prop="nationality" label="Nationality">
                <el-select id="country" v-model="formLabelAlign.nationality" placeholder="Select" filterable>
                  <el-option v-for="(item, index) in areaList" :key="index" :label="item.areaName" :value="item.areaId" />
                </el-select>
              </el-form-item>
              <!-- 隐藏的表单 -->
              <el-form-item style="display: none;" prop="orderID" label="orderID">
                <el-input type="input" id="orderId" v-model="formLabelAlign.orderID"></el-input>
              </el-form-item>
              <el-form-item style="display: none;" prop="money" label="money">
                <el-input type="input" id="amount" v-model="formLabelAlign.money"></el-input>
              </el-form-item>
              <el-form-item style="display: none;" prop="tradeName" label="tradeName">
                <el-input type="input" class="products" v-model="formLabelAlign.tradeName"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>

        <div class="bottom-tishi">
          <div class="img"></div>
          <span v-show="formLabelAlign.jpo === 13">VeriTrans SECURE payment</span>
          <span v-show="formLabelAlign.jpo === 14">GMO SECURE payment</span>
        </div>
        <div class="btn-btm">
          <el-button @click="oneContinue2" id="paySubmit">
            <span>CONTINUE</span>
            <i class="el-icon-arrow-right"></i>
          </el-button>
        </div>
      </div>
      <!-- 支付第三步 -->
      <div class="box" v-else-if="type == 3">
        <div style="height:466px;padding-top: 8px;">
          <div class="zfMain" style="border:0">
            <div class="jiage" style="border-color:#ccc">
              <p class="txt">Please confirm below details</p>
            </div>

            <!-- 表格 -->
            <div class="tableBox">
              <div class="tableBox-tit">Payment Details</div>
              <ul>
                <li>
                  <p>Payment Type</p>
                  <span>Card</span>
                </li>
                <li>
                  <p>Card Number</p>
                  <span>
                    {{ formLabelAlign.cardNumber.replace(' ', '').replace(' ', '').replace('
                    ','').replace(/^(\d{0})\d+(\d{2})$/,"$1**************$2")}}
                  </span>
                </li>
                <li>
                  <p>Expiry date</p>
                  <span>{{ formLabelAlign.expiryDate }}</span>
                </li>
                <li>
                  <p>Cardholder Name</p>
                  <span>{{ formLabelAlign.cardholderName }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="bottom-tishi">
          <div class="img"></div>
          <span v-show="formLabelAlign.jpo === 13">VeriTrans SECURE payment</span>
          <span v-show="formLabelAlign.jpo === 14">GMO SECURE payment</span>
        </div>
        <div class="btn-btm">
          <el-button @click="oneContinue3" id="paySubmit">
            <span>CONTINUE</span>
            <i class="el-icon-arrow-right"></i>
          </el-button>
        </div>
      </div>
      <div class="box asukaError" v-else>
        <div style="height:466px;padding-top: 8px;text-align:center">
          <div class="el-icon-circle-close" style="font-size:80px;color:#e61b23;margin-top:32px" />
          <hr style="border:1px solid #e61b23;margin:32px auto;width:95%;text-align:center" />
          <div class="error_txt_box">
            <h2>Error</h2>
            <h2 class="error_txt">
              Your payment has not been completed due to timeout, please return to the website to
              rebook.
            </h2>
            <h2 class="error_txt">ID:{{ outerOrderId }}</h2>
            <!-- <div>TIME:2023-01-06 09:41:52</div> -->
          </div>
        </div>
        <div class="bottom-tishi" style="margin:0;margin-top:10px">
          <div class="img"></div>
          <span v-show="formLabelAlign.jpo === 13">VeriTrans SECURE payment</span>
          <span v-show="formLabelAlign.jpo === 14">GMO SECURE payment</span>
        </div>
        <div class="btn-btm">
          <el-button @click="goCheckout" id="paySubmit">
            <span>COMPLETE</span>
            <i class="el-icon-arrow-right"></i>
          </el-button>
        </div>
      </div>
    </el-dialog>
    <!-- ASUKA验证失败引导弹窗 -->
    <el-dialog
      custom-class="guideDialog"
      :visible.sync="guideDialogVisible"
      width="30%"
      :close-on-click-modal="false"
      >
      <div class="guideDialog-nr">
        <!-- <p style="color:red;font-weight: 700;">The card you used may not be available.</p><br> -->
        <p>We apologize for the inconvenience. Please review the information below and proceed with your purchase.</p><br>
        <!-- <p style="font-weight: 700;font-size: 20px;">---What Should I do?---</p><br> -->
        <p>1. Please check the error message and try purchasing again.</p><br>
        <p>2. Contact our customer service via Chatbot (Bottom right of the page).</p><br>
        <!-- <p><span>booking@usjticketing.com</span></p><br> -->
        <p>3. Tikcets are available at our partner travel agencies. <a target="_blank" href="https://www.usj.co.jp/web/en/us/travel">https://www.usj.co.jp/web/en/us/travel</a></p>
        <!-- <p></p> -->
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { getAreaList, mdkPay, verifyPayment, gmoPay,asukaLog } from "@/api";
export default {
  data() {
    var cardholderEmailValidatePass = (rule, value, callback) => {
        // callback(new Error('请输入密码'));
        console.log(localStorage.getItem('peopleInfo'));
        let newEmail = JSON.parse(localStorage.getItem('peopleInfo')).repeatEmail
        if(value == newEmail){
          // console.log('通过');
        this.emailErrTxt = ''
        // this.emailErrTxtBr = false
          callback();
        }else {
        callback(new Error('Please check email again'));
          console.log('邮箱不一致');
        this.emailErrTxt = 'Please check email again'
        // this.emailErrTxtBr = true
        }
    };
    return {
      emailErrTxt:'',
      dialogVisible: false,
      asukaErrorBer: false, // 报错提示
      loadingbuer: false,
      guideDialogVisible: false,
      areaList: [
        { areaId: "AF", areaName: "Afghanistan" },
        { areaId: "AX", areaName: "Åland Islands" },
        { areaId: "AL", areaName: "Albania" },
        { areaId: "DZ", areaName: "Algeria" },
        { areaId: "AS", areaName: "American Samoa" },
        { areaId: "AD", areaName: "Andorra" },
        { areaId: "AO", areaName: "Angola" },
        { areaId: "AI", areaName: "Anguilla" },
        { areaId: "AQ", areaName: "Antarctica" },
        { areaId: "AG", areaName: "Antigua and Barbuda" },
        { areaId: "AR", areaName: "Argentina" },
        { areaId: "AM", areaName: "Armenia" },
        { areaId: "AW", areaName: "Aruba" },
        { areaId: "AU", areaName: "Australia" },
        { areaId: "AT", areaName: "Austria" },
        { areaId: "AZ", areaName: "Azerbaijan" },
        { areaId: "BS", areaName: "Bahamas" },
        { areaId: "BH", areaName: "Bahrain" },
        { areaId: "BD", areaName: "Bangladesh" },
        { areaId: "BB", areaName: "Barbados" },
        { areaId: "BY", areaName: "Belarus" },
        { areaId: "BE", areaName: "Belgium" },
        { areaId: "BZ", areaName: "Belize" },
        { areaId: "BJ", areaName: "Benin" },
        { areaId: "BM", areaName: "Bermuda" },
        { areaId: "BT", areaName: "Bhutan" },
        { areaId: "BO", areaName: "Bolivia (Plurinational State of)" },
        { areaId: "BQ", areaName: "Bonaire, Sint Eustatius and Saba" },
        { areaId: "BA", areaName: "Bosnia and Herzegovina" },
        { areaId: "BW", areaName: "Botswana" },
        { areaId: "BV", areaName: "Bouvet Island" },
        { areaId: "BR", areaName: "Brazil" },
        { areaId: "IO", areaName: "British Indian Ocean Territory" },
        { areaId: "BN", areaName: "Brunei Darussalam" },
        { areaId: "BG", areaName: "Bulgaria" },
        { areaId: "BF", areaName: "Burkina Faso" },
        { areaId: "BI", areaName: "Burundi" },
        { areaId: "CV", areaName: "Cabo Verde" },
        { areaId: "KH", areaName: "Cambodia" },
        { areaId: "CM", areaName: "Cameroon" },
        { areaId: "CA", areaName: "Canada" },
        { areaId: "KY", areaName: "Cayman Islands" },
        { areaId: "CF", areaName: "Central African Republic" },
        { areaId: "TD", areaName: "Chad" },
        { areaId: "CL", areaName: "Chile" },
        { areaId: "CN", areaName: "China" },
        { areaId: "CX", areaName: "Christmas Island" },
        { areaId: "CC", areaName: "Cocos (Keeling) Islands" },
        { areaId: "CO", areaName: "Colombia" },
        { areaId: "KM", areaName: "Comoros" },
        { areaId: "CG", areaName: "Congo" },
        { areaId: "CD", areaName: "Congo, Democratic Republic of the" },
        { areaId: "CK", areaName: "Cook Islands" },
        { areaId: "CR", areaName: "Costa Rica" },
        { areaId: "CI", areaName: "Côte d'Ivoire" },
        { areaId: "HR", areaName: "Croatia" },
        { areaId: "CU", areaName: "Cuba" },
        { areaId: "CW", areaName: "Curaçao" },
        { areaId: "CY", areaName: "Cyprus" },
        { areaId: "CZ", areaName: "Czechia" },
        { areaId: "DK", areaName: "Denmark" },
        { areaId: "DJ", areaName: "Djibouti" },
        { areaId: "DM", areaName: "Dominica" },
        { areaId: "DO", areaName: "Dominican Republic" },
        { areaId: "EC", areaName: "Ecuador" },
        { areaId: "EG", areaName: "Egypt" },
        { areaId: "SV", areaName: "El Salvador" },
        { areaId: "GQ", areaName: "Equatorial Guinea" },
        { areaId: "ER", areaName: "Eritrea" },
        { areaId: "EE", areaName: "Estonia" },
        { areaId: "SZ", areaName: "Eswatini" },
        { areaId: "ET", areaName: "Ethiopia" },
        { areaId: "FK", areaName: "Falkland Islands (Malvinas)" },
        { areaId: "FO", areaName: "Faroe Islands" },
        { areaId: "FJ", areaName: "Fiji" },
        { areaId: "FI", areaName: "Finland" },
        { areaId: "FR", areaName: "France" },
        { areaId: "GF", areaName: "French Guiana" },
        { areaId: "PF", areaName: "French Polynesia" },
        { areaId: "TF", areaName: "French Southern Territories" },
        { areaId: "GA", areaName: "Gabon" },
        { areaId: "GM", areaName: "Gambia" },
        { areaId: "GE", areaName: "Georgia" },
        { areaId: "DE", areaName: "Germany" },
        { areaId: "GH", areaName: "Ghana" },
        { areaId: "GI", areaName: "Gibraltar" },
        { areaId: "GR", areaName: "Greece" },
        { areaId: "GL", areaName: "Greenland" },
        { areaId: "GD", areaName: "Grenada" },
        { areaId: "GP", areaName: "Guadeloupe" },
        { areaId: "GU", areaName: "Guam" },
        { areaId: "GT", areaName: "Guatemala" },
        { areaId: "GG", areaName: "Guernsey" },
        { areaId: "GN", areaName: "Guinea" },
        { areaId: "GW", areaName: "Guinea-Bissau" },
        { areaId: "GY", areaName: "Guyana" },
        { areaId: "HT", areaName: "Haiti" },
        { areaId: "HM", areaName: "Heard Island and McDonald Islands" },
        { areaId: "VA", areaName: "Holy See" },
        { areaId: "HN", areaName: "Honduras" },
        { areaId: "HK", areaName: "Hong Kong" },
        { areaId: "HU", areaName: "Hungary" },
        { areaId: "IS", areaName: "Iceland" },
        { areaId: "IN", areaName: "India" },
        { areaId: "ID", areaName: "Indonesia" },
        { areaId: "IR", areaName: "Iran (Islamic Republic of)" },
        { areaId: "IQ", areaName: "Iraq" },
        { areaId: "IE", areaName: "Ireland" },
        { areaId: "IM", areaName: "Isle of Man" },
        { areaId: "IL", areaName: "Israel" },
        { areaId: "IT", areaName: "Italy" },
        { areaId: "JM", areaName: "Jamaica" },
        { areaId: "JP", areaName: "Japan" },
        { areaId: "JE", areaName: "Jersey" },
        { areaId: "JO", areaName: "Jordan" },
        { areaId: "KZ", areaName: "Kazakhstan" },
        { areaId: "KE", areaName: "Kenya" },
        { areaId: "KI", areaName: "Kiribati" },
        { areaId: "KP", areaName: "Korea (Democratic People's Republic of)" },
        { areaId: "KR", areaName: "Korea, Republic of" },
        { areaId: "KW", areaName: "Kuwait" },
        { areaId: "KG", areaName: "Kyrgyzstan" },
        { areaId: "LA", areaName: "Lao People's Democratic Republic" },
        { areaId: "LV", areaName: "Latvia" },
        { areaId: "LB", areaName: "Lebanon" },
        { areaId: "LS", areaName: "Lesotho" },
        { areaId: "LR", areaName: "Liberia" },
        { areaId: "LY", areaName: "Libya" },
        { areaId: "LI", areaName: "Liechtenstein" },
        { areaId: "LT", areaName: "Lithuania" },
        { areaId: "LU", areaName: "Luxembourg" },
        { areaId: "MO", areaName: "Macao" },
        { areaId: "MG", areaName: "Madagascar" },
        { areaId: "MW", areaName: "Malawi" },
        { areaId: "MY", areaName: "Malaysia" },
        { areaId: "MV", areaName: "Maldives" },
        { areaId: "ML", areaName: "Mali" },
        { areaId: "MT", areaName: "Malta" },
        { areaId: "MH", areaName: "Marshall Islands" },
        { areaId: "MQ", areaName: "Martinique" },
        { areaId: "MR", areaName: "Mauritania" },
        { areaId: "MU", areaName: "Mauritius" },
        { areaId: "YT", areaName: "Mayotte" },
        { areaId: "MX", areaName: "Mexico" },
        { areaId: "FM", areaName: "Micronesia (Federated States of)" },
        { areaId: "MD", areaName: "Moldova, Republic of" },
        { areaId: "MC", areaName: "Monaco" },
        { areaId: "MN", areaName: "Mongolia" },
        { areaId: "ME", areaName: "Montenegro" },
        { areaId: "MS", areaName: "Montserrat" },
        { areaId: "MA", areaName: "Morocco" },
        { areaId: "MZ", areaName: "Mozambique" },
        { areaId: "MM", areaName: "Myanmar" },
        { areaId: "NA", areaName: "Namibia" },
        { areaId: "NR", areaName: "Nauru" },
        { areaId: "NP", areaName: "Nepal" },
        { areaId: "NL", areaName: "Netherlands" },
        { areaId: "NC", areaName: "New Caledonia" },
        { areaId: "NZ", areaName: "New Zealand" },
        { areaId: "NI", areaName: "Nicaragua" },
        { areaId: "NE", areaName: "Niger" },
        { areaId: "NG", areaName: "Nigeria" },
        { areaId: "NU", areaName: "Niue" },
        { areaId: "NF", areaName: "Norfolk Island" },
        { areaId: "MK", areaName: "North Macedonia" },
        { areaId: "MP", areaName: "Northern Mariana Islands" },
        { areaId: "NO", areaName: "Norway" },
        { areaId: "OM", areaName: "Oman" },
        { areaId: "PK", areaName: "Pakistan" },
        { areaId: "PW", areaName: "Palau" },
        { areaId: "PS", areaName: "Palestine, State of" },
        { areaId: "PA", areaName: "Panama" },
        { areaId: "PG", areaName: "Papua New Guinea" },
        { areaId: "PY", areaName: "Paraguay" },
        { areaId: "PE", areaName: "Peru" },
        { areaId: "PH", areaName: "Philippines" },
        { areaId: "PN", areaName: "Pitcairn" },
        { areaId: "PL", areaName: "Poland" },
        { areaId: "PT", areaName: "Portugal" },
        { areaId: "PR", areaName: "Puerto Rico" },
        { areaId: "QA", areaName: "Qatar" },
        { areaId: "RE", areaName: "Réunion" },
        { areaId: "RO", areaName: "Romania" },
        { areaId: "RU", areaName: "Russian Federation" },
        { areaId: "RW", areaName: "Rwanda" },
        { areaId: "BL", areaName: "Saint Barthélemy" },
        {
          areaId: "SH",
          areaName: "Saint Helena, Ascension and Tristan da Cunha"
        },
        { areaId: "KN", areaName: "Saint Kitts and Nevis" },
        { areaId: "LC", areaName: "Saint Lucia" },
        { areaId: "MF", areaName: "Saint Martin (French part)" },
        { areaId: "PM", areaName: "Saint Pierre and Miquelon" },
        { areaId: "VC", areaName: "Saint Vincent and the Grenadines" },
        { areaId: "WS", areaName: "Samoa" },
        { areaId: "SM", areaName: "San Marino" },
        { areaId: "ST", areaName: "Sao Tome and Principe" },
        { areaId: "SA", areaName: "Saudi Arabia" },
        { areaId: "SN", areaName: "Senegal" },
        { areaId: "RS", areaName: "Serbia" },
        { areaId: "SC", areaName: "Seychelles" },
        { areaId: "SL", areaName: "Sierra Leone" },
        { areaId: "SG", areaName: "Singapore" },
        { areaId: "SX", areaName: "Sint Maarten (Dutch part)" },
        { areaId: "SK", areaName: "Slovakia" },
        { areaId: "SI", areaName: "Slovenia" },
        { areaId: "SB", areaName: "Solomon Islands" },
        { areaId: "SO", areaName: "Somalia" },
        { areaId: "ZA", areaName: "South Africa" },
        {
          areaId: "GS",
          areaName: "South Georgia and the South Sandwich Islands"
        },
        { areaId: "SS", areaName: "South Sudan" },
        { areaId: "ES", areaName: "Spain" },
        { areaId: "LK", areaName: "Sri Lanka" },
        { areaId: "SD", areaName: "Sudan" },
        { areaId: "SR", areaName: "Suriname" },
        { areaId: "SJ", areaName: "Svalbard and Jan Mayen" },
        { areaId: "SE", areaName: "Sweden" },
        { areaId: "CH", areaName: "Switzerland" },
        { areaId: "SY", areaName: "Syrian Arab Republic" },
        { areaId: "TW", areaName: "Taiwan" },
        { areaId: "TJ", areaName: "Tajikistan" },
        { areaId: "TZ", areaName: "Tanzania, United Republic of" },
        { areaId: "TH", areaName: "Thailand" },
        { areaId: "TL", areaName: "Timor-Leste" },
        { areaId: "TG", areaName: "Togo" },
        { areaId: "TK", areaName: "Tokelau" },
        { areaId: "TO", areaName: "Tonga" },
        { areaId: "TT", areaName: "Trinidad and Tobago" },
        { areaId: "TN", areaName: "Tunisia" },
        { areaId: "TR", areaName: "Türkiye" },
        { areaId: "TM", areaName: "Turkmenistan" },
        { areaId: "TC", areaName: "Turks and Caicos Islands" },
        { areaId: "TV", areaName: "Tuvalu" },
        { areaId: "UG", areaName: "Uganda" },
        { areaId: "UA", areaName: "Ukraine" },
        { areaId: "AE", areaName: "United Arab Emirates" },
        {
          areaId: "GB",
          areaName: "United Kingdom of Great Britain and Northern Ireland"
        },
        { areaId: "US", areaName: "United States of America" },
        { areaId: "UM", areaName: "United States Minor Outlying Islands" },
        { areaId: "UY", areaName: "Uruguay" },
        { areaId: "UZ", areaName: "Uzbekistan" },
        { areaId: "VU", areaName: "Vanuatu" },
        { areaId: "VE", areaName: "Venezuela (Bolivarian Republic of)" },
        { areaId: "VN", areaName: "Viet Nam" },
        { areaId: "VG", areaName: "Virgin Islands (British)" },
        { areaId: "VI", areaName: "Virgin Islands (U.S.)" },
        { areaId: "WF", areaName: "Wallis and Futuna" },
        { areaId: "EH", areaName: "Western Sahara" },
        { areaId: "YE", areaName: "Yemen" },
        { areaId: "ZM", areaName: "Zambia" },
        { areaId: "ZW", areaName: "Zimbabwe" }
      ],
      newTypeList: [],
      loading: false,
      type: 1,
      payType: 2,
      outerOrderId: "",
      formLabelAlign: {
        // 显示数据
        cardNumber: "",
        expiryDate: "",
        cvv: "",
        cardholderName: "",
        cardholderEmail: "",
        cardholderPhone: "",
        nationality: "",
        // 隐藏数据
        orderID: "",
        money: "",
        tradeName: "",
        jpo: ""
      },
      // 校验
      formLabelAlignRules: {
        cardNumber: [
          {
            required: true
          },
          {
            pattern: /^.{13,19}$/,
            trigger: "blur"
          }
        ],
        expiryDate: [
          {
            required: true,
            trigger: "blur"
          },
          {
            pattern: /^.{5,}$/,
            trigger: "blur"
          },
          {
            validator: (rule, value, callback) => {
              console.log(value);
              let yue = value.slice(0, 2);
              console.log(yue);
              if (+yue <= 12) {
                callback();
                console.log("正确");
              } else {
                console.log("错误");
                callback(new Error("错误"));
              }
            },
            trigger: "blur"
          }
        ],
        cvv: [
          {
            required: true
          },
          {
            pattern: /^[0-9]{3,}$/,
            trigger: "blur"
          }
        ],
        cardholderName: [
          {
            required: true
          }
        ],
        cardholderEmail: [
          {
            required: true
          },
          {
            pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            trigger: "blur"
          },
          { validator: cardholderEmailValidatePass, trigger: 'blur' }
        ],
        cardholderPhone: [
          {
            required: true
          }
        ],
        nationality: [
          {
            required: true,
            trigger: "change"
          }
        ]
      }
    };
  },
  mounted() {
    // this.stripe()
  },
  methods: {
    nameInput(e) {
      // console.log(e);
      this.formLabelAlign.cardholderName = e.replace(/[^a-zA-Z]/g, "");
      // console.log(this.formLabelAlign.cardholderName);
    },
    // 地方按字母排序
    // areaSort(a, b) {
    //   if (a.areaName.toLowerCase() < b.areaName.toLowerCase()) return -1
    //   if (a.areaName.toLowerCase() > b.areaName.toLowerCase()) return 1
    //   return 0
    // },
    // 获取国籍（地区）
    // getAreaList() {
    //   const param = {
    //     parentIds: '100,101,102,103,104,105'
    //   }
    //   getAreaList(param).then(res => {
    //     if (res.data.code === 0) {
    //       this.areaList = res.data.data
    //       res.data.data.map(item => {
    //         item.areaName = item.areaName.trim() // 去掉空格
    //       })
    //       this.areaList.sort(this.areaSort)
    //     } else {
    //       this.areaList = []
    //     }
    //   })
    // },
    // 聚焦
    focusFn(type) {
      if (type == 1) {
        // 卡号输入框聚焦时 清除报错提示
        this.asukaErrorBer = false;
        this.formLabelAlign.cardNumber = this.formLabelAlign.cardNumber.replace(
          /\s/g,
          ""
        );
      } else if (type == 2) {
        if (this.formLabelAlign.expiryDate.length > 3) {
          this.formLabelAlign.expiryDate =
            this.formLabelAlign.expiryDate.toString().slice(0, 2) +
            this.formLabelAlign.expiryDate.toString().slice(3, 5);
        }
      }
    },
    // 失焦
    blurFn(type) {
      if (type == 1) {
        // 失焦时去除两边空格
        this.formLabelAlign.cardNumber = this.formLabelAlign.cardNumber.trim();
        // this.formLabelAlign.cardNumber = this.formLabelAlign.cardNumber.replace(/(\s)/g,'').replace(/(\d{4})/g,'$1 ').replace(/\s*$/,'')
      } else if (type == 2) {
        if (this.formLabelAlign.expiryDate.length > 3) {
          this.formLabelAlign.expiryDate =
            this.formLabelAlign.expiryDate.toString().substring(0, 2) +
            "/" +
            this.formLabelAlign.expiryDate.toString().substring(2);
        }
      }
    },
    goCheckout() {
      this.$router.replace({ path: "/checkOut", query: { payFail: 1 } });
    },
    init() {
      this.dialogVisible = true;
      this.formLabelAlign.jpo === 13 ? (this.payType = 1) : (this.payType = 2);
      console.log(this.formLabelAlign);
    },
    oneContinue() {
      this.type = 2;
      let icon = document.querySelector(".el-dialog__headerbtn i");
      icon.className = "el-icon-arrow-left";

      // 初始化
      aerosmith.init();
      this.loadingbuer = true;
      this.asukaErrorBer = false;

      setTimeout(() => {
        this.loadingbuer = false;
      }, 3000);
    },
    async oneContinue2() {
      try {
        await this.$refs.formLabelAlignRules.validate();
        this.loadingbuer = true;
        let that = this;
        if (typeof aerosmith === "undefined") {
          console.log("等于undefined");
          return;
        } else {
          // that.oneContinue3();
          aerosmith.checkScore(
            function (reason) {
              that.loadingbuer = false;
              console.log('reason:' + reason)
              asukaLog({outerOrderId:JSON.parse(localStorage.getItem("outerOrderId")),asukaResult:reason}).then(res=>{
                console.log(res)
              })
              // that.payType === 2 ? alert('Asuka checkScore return:'+ reason) :''
              if (reason == 'LRT') {
                // alert('ASUKA认证低风险通过')
                // that.type = 3;
                // 获取score_base_key
                console.log('baseKey', aerosmith.getScoreBaseKey());
                // 调起支付
                that.oneContinue3(aerosmith.getScoreBaseKey());
                // that.oneContinue3();
                console.log('ASUKA认证低风险通过', that.formLabelAlign);
              } else if (reason == 'BIAT') {
                // alert('ASUKA认证中风险但通过')
                // that.type = 3;
                // 获取score_base_key
                console.log('baseKey', aerosmith.getScoreBaseKey());
                // 调起支付
                that.oneContinue3(aerosmith.getScoreBaseKey());
                // that.oneContinue3();
                console.log('ASUKA认证低风险通过', that.formLabelAlign);
              } else {
                // console.log('baseKey', aerosmith.getScoreBaseKey());
                console.log('ASUKA认证高风险限制支付：' + reason)
                that.asukaErrorBer = true;
              }
            },
            function (reason) {
              that.loadingbuer = false;
              console.log('reason:' + reason)
              asukaLog({outerOrderId:JSON.parse(localStorage.getItem("outerOrderId")),asukaResult:reason}).then(res=>{
                console.log(res)
              })
              // that.payType === 2 ? alert('Asuka checkScore return:'+ reason) :''
              if (reason == 'timeout') {
                console.log('ASUKA认证超时')
                that.asukaErrorBer = true;
              } else if (reason == 'failed') {
                // alert('ASUKA认证低失败')
                console.log('ASUKA认证低失败');
                that.asukaErrorBer = true;
              } else {
                console.log('ASUKA认证失败:' + reason)
                that.asukaErrorBer = true;
              }

              // 失败引导用户弹窗
              // 打开弹窗
              that.guideDialogVisible = true
            }
          )
        }
        // that.type = 3;
        // console.log(newType);
      } catch (error) {
        this.loadingbuer = false;
        console.log("error", error);
      }
    },
    async oneContinue3(ScoreBaseKey) {
      // google统计-mdk支付事件埋点
      this.$gtag.event("checkoutPayOrderContinue", {
        event_category: "checkoutPayOrderContinue",
        event_label: "checkoutPayOrderContinue",
        value: 1000
      });
      console.log("支付");
      // test
      // if (this.payType === 2) {
      //   Multipayment.init(process.env.VUE_APP_GMO_SHOPID);
      //   console.log(this.formLabelAlign.cardNumber,this.formLabelAlign.cardExpire);
      //   Multipayment.getToken({
      //     cardNo: this.formLabelAlign.cardNumber,
      //     expire: this.formLabelAlign.cardExpire,
      //     securityCode: this.formLabelAlign.securityCode,
      //     holderName: this.formLabelAlign.cardholderName,
      //     tokenNumber: "1"
      //   },this.gmoTest);
      // }
      // end test
      let params = {
        amount: this.formLabelAlign.money.toString(),
        cardExpire: this.formLabelAlign.expiryDate,
        cardHolderName: this.formLabelAlign.cardholderName,
        // cardNumber: this.formLabelAlign.cardNumber.replace(' ','').replace(' ','').replace(' ',''),
        cardNumber: this.formLabelAlign.cardNumber,
        // jpo: this.formLabelAlign.jpo.toString(),
        jpo: "10",
        outerOrderId: this.formLabelAlign.orderID,
        securityCode: this.formLabelAlign.cvv,
        asukaScoreBaseKey:ScoreBaseKey,
        cardholderEmail: this.formLabelAlign.cardholderEmail
      };
      let params1 = {
        amount: this.formLabelAlign.money.toString(),
        cardExpire: this.formLabelAlign.cardExpire,
        cardHolderName: this.formLabelAlign.cardholderName,
        // cardNumber: this.formLabelAlign.cardNumber.replace(' ','').replace(' ','').replace(' ',''),
        cardNumber: this.formLabelAlign.cardNumber,
        // jpo: this.formLabelAlign.jpo.toString(),
        // jpo: '10',
        outerOrderId: this.formLabelAlign.orderID,
        securityCode: this.formLabelAlign.securityCode,
        email: this.formLabelAlign.cardholderEmail,
        asukaScoreBaseKey: ScoreBaseKey,
        // token:gmoToken
      };
      const outerOrderId = JSON.parse(localStorage.getItem("outerOrderId"));
      this.outerOrderId = outerOrderId;

      let confirm = await verifyPayment(outerOrderId);
      if (confirm.data.code === 0) {
        // 13:mdk 其他:gmo
        let res = this.payType === 1 ? await mdkPay(params) : await gmoPay(params1);
        console.log("支付调起后", res);
        if (this.payType !== 1) {
          if (res.data.code === 0) {
            // 读取本地保存的html数据，使用新窗口打开
            window.open(res.data.data, "_self");
            //newWin.document.write(localStorage.getItem("callbackHTML"));
            // newWin.document.write(res.data.data);
            // 关闭输出流
            // newWin.document.close();
          } else {
            this.asukaErrorBer = true;
            if(res.data.msg.indexOf('{')!==-1){
              let errorMsg = JSON.parse(res.data.msg)
              console.log(res.data.msg,errorMsg)
              if(errorMsg.errCode){
                this.$router.replace({ path: "/checkOut", query: { payFail: 1,errCode:errorMsg.errCode,errInfo:errorMsg.errInfo } });
              }
            }
          }
        } else {
          // 读取本地保存的html数据，使用新窗口打开
          var newWin = window.open("", "_self");
          //newWin.document.write(localStorage.getItem("callbackHTML"));
          newWin.document.write(res.data);
          // 关闭输出流
          newWin.document.close();
        }
      } else {
        this.loadingbuer = false;
        this.type = 4;
        let iconButtonFather = document.querySelector(".el-dialog__headerbtn")
          .parentElement;
        iconButtonFather.removeChild(iconButtonFather.childNodes[1]);
        // icon.className = '';
        // iconButton.className = 'not_button'
        this.$message({
          type:'error',
          message:"Your credit card's 3ds authentication failed",
          duration:1500
        })
      }

      // console.log(res.data);
      // window.location.href = 'about:blank';
      // document.write(res.data);

      // let newWindow =window.open('about:blank');
      // newWindow.document.write(res.data);
      // newWindow.focus();

      //window.localStorage.removeItem("callbackHTML");
      //window.localStorage.setItem("callbackHTML", res.data);
    },
    gmoTest(response){
      console.log('gmo支付测试');
      console.log(response);
      console.log(`gmo:${response}`);
      if(String(response.resultCode) !== '000'){
        alert('错误')
      }else{
        console.log(response);
        gmoToken = response.tokenObject.token
      }
   
    },
    handleClose() {
      if (this.type == 1) {
        this.dialogVisible = false;
        this.$router.replace({ path: "/checkOut", query: { payFail: 1 } });
      } else {
        this.type -= 1;
        if (this.type == 1) {
          let icon = document.querySelector(".el-dialog__headerbtn i");
          icon.className = "el-icon-close";
        }
      }
    },
    test() {
      let input = document.getElementsByTagName("input");
      console.log(input);
    },
    async stripe() {
      await stripePaymentIntent(localStorage.getItem("outerOrderId"));
    }
  },
  created() {
    // this.getAreaList()
  }
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog {
  width: 420px !important;
  height: 640px;
  background-color: #f2f2f2;
  border-radius: 5px;

  .el-dialog__body {
    padding: 0;
  }

  .el-dialog__header {
    padding: 0;
    border-bottom: 2px solid #cccccc;
    background-color: #fff;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    .title {
      flex: 1;

      .right {
        line-height: 38px;
        padding: 0 15px;
        display: flex;
        justify-content: space-between;
        font-weight: 700;
        border-left: 1px solid #f2f2f2;

        h1 {
          font-size: 17px;
          width: 140px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }

        span {
          font-size: 15px;
          color: #5d50a0;
        }
      }
    }

    .el-dialog__headerbtn {
      position: unset;
      padding: 18px 8px;

      &:hover {
        background-color: #f2f2f2;
        border-top-left-radius: 5px;

        .el-dialog__close {
          color: unset;
        }
      }

      .el-icon-close:before,
      .el-icon-arrow-left:before {
        font-size: 22px;
        color: #ccc;
      }
    }
  }

  .zfMain {
    margin: 8px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 8px;
    margin-top: 0;

    .jiage {
      background-color: #fff;
      border-bottom: 1px solid #5d50a0;
      color: #5d50a0;
      display: flex;
      justify-content: space-between;

      .txt {
        flex: 1;
        padding: 14px 16px;
        font-size: 15px;
      }

      .price {
        margin-right: 16px;

        .price-txt {
          margin-right: 4px;
          vertical-align: 12px;
          font-weight: 400;
          font-size: 20px;
        }

        .price-price {
          font-size: 40px;
          font-weight: 500;
        }
      }
    }

    // 表格静态
    .tableBox {
      background-color: #fff;
      margin-top: 8px;
      font-weight: 500;

      .tableBox-tit {
        padding: 8px 16px;
        font-size: 15px;
        background-color: #dceef7;
        border-bottom: 1px solid #dceef7;
        background-image: repeating-linear-gradient(
          135deg,
          white,
          white 1px,
          transparent 2px,
          transparent 2px,
          white 3px
        );
      }

      ul {
        padding: 0 16px;

        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 8px 0;
          font-size: 14px;
          color: #666666;
          border-bottom: 1px solid #f2f2f2;
        }
      }
    }
  }

  // 表单静态
  .formBox {
    background-color: #fff;
    margin: 8px;

    .el-form-item {
      border-bottom: 1px solid #f2f2f2;
      // padding: 0 16px 0;
      margin-bottom: 0;

      &.is-error {
        background-color: #ffc7c9;
        background-image: repeating-linear-gradient(
          135deg,
          white,
          white 1px,
          transparent 2px,
          transparent 2px,
          white 3px
        );

        .el-input__inner {
          background-color: #ffc7c9;
          background-image: repeating-linear-gradient(
            135deg,
            white,
            white 1px,
            transparent 2px,
            transparent 2px,
            white 3px
          );
        }
      }

      .el-form-item__error {
        display: none;
      }
    }

    .el-select {
      width: 100%;
    }

    .el-form-item__label {
      line-height: unset;
      padding: 0;
      color: #999999;
      font-size: 14px;
      margin-left: 16px;

      &:before {
        content: "";
      }
    }

    .el-input--suffix {
      .el-input__suffix {
        display: none;
      }
    }

    .el-input__inner {
      border: 0;
      padding: 0 16px;
      border-radius: 0;
      font-size: 16px;
      color: #555555;
      letter-spacing: 1px;

      &:focus {
        outline: none;
        border-bottom: 1px solid #5d50a0;
      }
    }
  }

  .bottom-tishi {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #cccccc;
    margin: 8px;
    margin-top: 0;

    .img {
      display: inline-block;
      background-image: url("../../assets/pop-card-Sprite.png");
      background-position: -50px -69px;
      width: 46px;
      height: 45px;
      display: inline-block;
      background-size: 636% !important;
    }

    span {
      margin-left: 10px;
      color: #999999;
      font-size: 16px;
    }
  }

  .btn-btm {
    background-color: #5d50a0;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    .el-button {
      color: #fff;
      background-color: #5d50a0;
      width: 100%;
      padding: 20px 16px;
      border: 0;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      font-size: 18px;
      font-weight: 700;

      &:hover {
        background-color: #463c78;
        border-top: 1px solid #5d50a0;
      }

      & > span {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  // 报错提示
  .asukaError {
    color: #e61b23;
    line-height: 60px;
    // text-align: center;
    // height: 60px;
    // padding: 10px 20px;
    line-height: 20px;
    background-color: #ffc7c9;
    background-image: repeating-linear-gradient(
      135deg,
      white,
      white 1px,
      transparent 2px,
      transparent 2px,
      white 3px
    );

    span {
      text-align: left;
    }

    .error_txt_box {
      text-align: center;
      margin-top: 3rem;
      padding: 0 5px;
    }
  }
}

/deep/.guideDialog{
  width: unset;
  height: unset;
  background-color: #fff;
  .el-dialog__header{
    flex-direction: row;
    border-bottom: 0 solid #000;
    .el-dialog__headerbtn {
      padding: 5px;
      .el-icon-close:before {
        color: #000;
        font-size: 30px;
      }
    }
  }
  .el-dialog__body{
    padding: 16px;
    padding-top: 0;
    color: #000;
  }
  .guideDialog-nr{
    p {
      span {
        font-weight: 700;
      }
    }
  }
}


@media screen and (max-width: 567px) {
  /deep/.el-dialog {
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    min-width: 350px;
  }

  .btn-btm {
    position: unset !important;
  }
}

.not_button {
  border: none;
  outline: none;

  width: 1px;
  height: 1px;
  background-color: transparent;
}

.error_txt {
  color: #e61b23;
  margin-top: 1rem;
  font-weight: normal;
}

// stripe样式start
// .form{
//   width: 300px;
//   #card-element{
//     box-shadow: 0 1px 1px 1px gray;
//     .__PrivateStripeElement{
//       height: 30px !important;
//       line-height: 30px !important;
//     }

//   }
//   #paySubmit{
//     margin-top: 15px;
//     font-weight: bold;
//     background-color: #556cd6;
//     color: white;
//     height: 30px;
//     line-height: 30px;
//     border: 1px solid;
//     border-radius: 5px;
//     width: 300px;
//     text-align: center;
//   }
// }
// stripe样式end
</style>
